<template>
  <div>
    <permission-list></permission-list>
  </div>
</template>

<script>

export default {
}
</script>


